/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Please note that order is relevant. The 'dataTables' file must be
// imported before any other file using chicargoDataTable is imported.

import 'dataTables';
import 'dataTablesInitializer';

import 'admin/authorized_devices';
import 'admin/slides';
import 'airlines';
import 'airport_selection';
import 'auto_logout';
import 'billing_items';
import 'bootstrap';
import 'browser_notice';
import 'client_usage_reports';
import 'clients';
import 'collapsible';
import 'companies';
import 'data_confirm_modal';
import 'datetime_selection';
import 'damage_types_select';
import 'drop_layer';
import 'driver_registrations';
import 'ekko-lightbox';
import 'elevio';
import 'employees';
import 'filterbar';
import 'filters';
import 'form';
import 'ground_handling_agents';
import 'history_detect';
import 'inbound_arrivals';
import 'inbound_arrival_shipments';
import 'inbound_arrival_loads';
import 'inbound_manifests';
import 'inbound_master_loads';
import 'inbound_master_shipments';
import 'inbound_masters';
import 'inbound_pickup_loads';
import 'inbound_pickup_shipments';
import 'inbound_pickups';
import 'inbound_shipments';
import 'inbound_tour_loads';
import 'inbound_tours';
import 'inbound_ulds';
import 'label_print';
import 'locations_select';
import 'legacy_reports';
import 'loading_spinner';
import 'master_helper';
import 'master_number_input_event_handler';
import 'modal';
import 'moment_configuration';
import 'nested_billing_items';
import 'nested_customs_events';
import 'nested_measurements';
import 'nested_ssccs';
import 'nested_work_items';
import 'src/outbound/packing';
import 'outbound_arrival_shipments';
import 'outbound_arrivals';
import 'outbound_manifests';
import 'outbound_master_shipments';
import 'outbound_masters';
import 'outbound_pickups';
import 'outbound_pickup_loads';
import 'outbound_shipments';
import 'outbound_tour_masters';
import 'outbound_tours';
import 'outbound_ulds';
import 'sane_enter_key';
import 'shipment_helper';
import 'station_selection';
import 'stocktaking_shipment_findings';
import 'stocktaking_shipment_missings';
import 'stocktakings';
import '@uppy/drag-drop';
import '@uppy/core';
import '@uppy/dashboard';
import '@uppy/aws-s3';
import 'uppy';
import 'work_item_shipments';
import 'work_items';

// The next three lines are only required for Internet Explorer (which
// does not support the fetch API).

import 'core-js';
import Promise from 'promise-polyfill';
import 'whatwg-fetch';

import { Application } from 'stimulus';
import { definitionsFromContext } from 'stimulus/webpack-helpers';

const application = Application.start();
const context = require.context('../controllers', true, /\.js$/);
application.load(definitionsFromContext(context));
