const airportAutocompleteSelector = '.airport, [data-autocomplete="airport"]';
const gateAutocompleteSelector = '.gates, [data-autocomplete="gate"]';

export const autocompleteAirports = (
  airports,
  autocompleteSelector = airportAutocompleteSelector,
  customAutocompleteConfig,
) => {
  const config = {
    autoFocus: true,
    close: (event, _io) => {
      // Indirectly trigger the change event of the input field so it doesn't get triggered on the user's blur a second time. Sorry.
      $(event.target).blur().focus();
    },
    delay: 0,
    source: airports,
    ...customAutocompleteConfig,
  };

  $(autocompleteSelector)
    .autocomplete(config)
    .each((i, el) => {
      $(el).data('ui-autocomplete')._renderItem = (ul, item) => {
        return $('<li>')
          .append(
            '<div>' +
              item.value +
              '<br><small>' +
              item.name +
              ', ' +
              item.country +
              '</small></div>',
          )
          .appendTo(ul);
      };
    });
};

const handleAirports = () => {
  const airportsField = document.querySelector(airportAutocompleteSelector);
  if (airportsField) {
    const airportsJson = airportsField.dataset.airports;
    if (!airportsJson) {
      console.warn('airports dataset missing');
    } else {
      const airports = JSON.parse(airportsJson);
      autocompleteAirports(airports);
    }
  }
};

const autocomplete_gates = (gates) => {
  $(gateAutocompleteSelector)
    .autocomplete({
      autoFocus: true,
      close: (event, _io) => {
        // Indirectly trigger the change event of the input field so it doesn't get triggered on the user's blur a second time. Sorry.
        $(event.target).blur().focus();
      },
      delay: 1,
      source: gates,
    })
    .each((i, el) => {
      $(el).data('ui-autocomplete')._renderItem = (ul, item) => {
        return $('<li>')
          .append('<div>' + item.value + '</div>')
          .appendTo(ul);
      };
    });
};

const handleGates = () => {
  const gatesField = document.querySelector(gateAutocompleteSelector);
  if (gatesField) {
    const gatesJson = gatesField.dataset.gates;
    if (!gatesJson) {
      console.warn('gates dataset missing');
    } else {
      const gates = JSON.parse(gatesJson);
      autocomplete_gates(gates);
    }
  }
};

document.addEventListener('DOMContentLoaded', () => {
  handleAirports();
});

document.addEventListener('DOMContentLoaded', () => {
  handleGates();
});

$(document).ajaxComplete(() => {
  handleAirports();
});

$(document).ajaxComplete(() => {
  handleGates();
});
