$(() => {
  const initPopovers = () => {
    $("a[rel~=popover], .has-popover").popover({ html: true });
  };
  initPopovers();

  const initTooltips = () => {
    $("a[rel~=tooltip], .has-tooltip").tooltip();
  };
  initTooltips();

  $(document).ajaxComplete(() => {
    initPopovers();
    initTooltips();
  });
});

