/* eslint-env jquery */
$(() => {
  window.toggleWrapper = () => {
    const $wrapper = $('#wrapper');

    if ($wrapper.hasClass('toggled')) {
      $wrapper.removeClass('toggled');
      return sessionStorage.setItem('showFilterbar', 'false');
    }
    $wrapper.addClass('toggled');
    sessionStorage.setItem('showFilterbar', 'true');
  };

  const showHideFilterbar = () => {
    let filterbarContent;
    let showFilterbar = sessionStorage.getItem('showFilterbar');
    const $wrapper = $('#wrapper');
    if ($wrapper.data('filterbarContent')) {
      filterbarContent = 'true';
    } else {
      filterbarContent = 'false';
    }

    if (!showFilterbar) {
      sessionStorage.setItem('showFilterbar', 'true');
      showFilterbar = sessionStorage.getItem('showFilterbar');
    }

    if (showFilterbar === 'true' && filterbarContent === 'true') {
      return $wrapper.addClass('toggled');
    }
    $wrapper.removeClass('toggled');
  };

  showHideFilterbar();

  $.fn.dataTable.ext.errMode = 'none';

  $(document).on('click', '.advanced_filters', function () {
    const $panel = $(this).find('[aria-expanded]');
    const panelState =
      $panel.attr('aria-expanded') === 'true' ? 'show' : 'hide';

    localStorage.setItem($panel.data('storagekey'), panelState);
  });

  // See: https://datatables.net/reference/event/error
  // for documentation about this DataTable event
  $(document).on(
    'error.dt',
    'table.dataTable',
    (event, settings, techNote, message) => {
      // show error modal
      dataConfirmModal.confirm({
        title: 'Fehler',
        text: `Bei der Suche ist ein Fehler aufgetreten – bitte überprüfen Sie Ihre Eingabe.<br><br><code>Fehlercode: ${techNote}</code>`,
        cancelClass: 'hidden',
        commitClass: 'btn-primary',
      });
      $('.browser-notice').fadeIn();

      // stop spinning cursor
      $('html:not(.no-loading-indicator)').removeClass('loading-pre');
      // stop spinner
      $('.dataTables_wrapper').removeClass('loading');

      const error_log = {
        error: message,
        // Note that airbrake.notify stringifies the data you send it
        // and some settings contain circular references and can't be
        // stringified. Sending only a few specific settings.
        context: {
          dataTableErrorNumber: techNote,
        },
      };
      if (
        process.env.RAILS_ENV !== 'development' &&
        process.env.RAILS_ENV !== 'test'
      ) {
        airbrake.notify(error_log);
      } else {
        console.warn(error_log);
      }

      // See: https://developer.mozilla.org/en-US/docs/Web/API/GlobalEventHandlers/onerror
      // When the function returns true, this prevents the firing of the default event handler
      return true;
    },
  );
  // This is neccessary because if the event is handled by window.onerror
  // Then Airbrake will send the event object to airbrake creating the famous Error: [object Object]

  $('div.advanced_filters #advanced-filter-message-box .close').click((e) => {
    e.preventDefault();
    e.stopImmediatePropagation();
    $('#advanced-filter-message-box').hide();
  });

  $('div.advanced_filters .panel-title a').click((e) => {
    const inputs = $('div.advanced_filters').find(':input');
    const values = $.map(inputs, (e) => $(e).val());

    // if there are only empty strings in them values - Schwingschleifer - that means no filter is used.
    const empty = values.every((e) => e === '');

    if (!empty) {
      const active_filters = [];

      const object = inputs.toArray();
      for (const i in object) {
        const input = object[i];
        if ($(input).val() !== '') {
          const label_html = $(input).prevUntil('div', 'label').html();
          const input_val = $(input).val();

          active_filters.push(`${label_html}`);
        }
      }

      e.preventDefault();
      e.stopImmediatePropagation();
      $('#advanced-filter-message-box p').html(
        `Die zusätzlichen Filter können nicht ausgeblendet werden.</br> Es sind noch Filter aktiv:</br><b>${active_filters.join(
          '</b></br><b>',
        )}</b>`,
      );
      $('#advanced-filter-message-box').show();
    }
  });

  const increment_counter = (counterId) => {
    const new_counter = `${parseInt($(`#${counterId}`).html(), 10) + 1}`;
    $(`#${counterId}`).html(new_counter);
  };

  const decrement_counter = (counterId) => {
    const new_counter = `${parseInt($(`#${counterId}`).html(), 10) - 1}`;
    $(`#${counterId}`).html(new_counter);
  };

  const compareToExpectedPiecesCount = (piecesCount) => {
    const expectedPiecesCount = parseInt($('#expected_pieces_count').text());

    const $expectedPiecesCountComparison = $(
      '#expected-pieces-count-comparison',
    );
    const $linkShipmentsButton = $('#link_shipments_button');

    if (piecesCount < expectedPiecesCount) {
      $expectedPiecesCountComparison.text('Nicht genügend Packstücke');
      $expectedPiecesCountComparison.removeClass('alert-warning');
      $expectedPiecesCountComparison.addClass('alert alert-info');
      $linkShipmentsButton.prop('disabled', false);
    }
    if (piecesCount === expectedPiecesCount || piecesCount === 0) {
      $expectedPiecesCountComparison.removeClass(
        'alert alert-info alert-warning',
      );
      $expectedPiecesCountComparison.text('');
      $linkShipmentsButton.prop('disabled', false);
    }
    if (piecesCount > expectedPiecesCount) {
      $expectedPiecesCountComparison.text(
        'Zu viele Packstücke, Verknüpfen nicht möglich',
      );
      $expectedPiecesCountComparison.removeClass('alert-info');
      $expectedPiecesCountComparison.addClass('alert alert-warning');
      $linkShipmentsButton.prop('disabled', true);
    }
  };

  const push_row = (t, row, target) => {
    window.markedRowIds.push(t.id);
    row.fadeOut('fast', () => row.appendTo(target).fadeIn('fast'));
  };

  const addToPiecesCount = (piecesCounterId, piecesToAdd) => {
    if ($(`#${piecesCounterId}`).length) {
      const pieceCount = `${
        parseInt($(`#${piecesCounterId}`).html(), 10) + piecesToAdd
      }`;
      $(`#${piecesCounterId}`).html(pieceCount);
      compareToExpectedPiecesCount(parseInt(pieceCount));
    }
  };

  const subtractFromPiecesCount = (piecesCounterId, piecesToSubtract) => {
    if ($(`#${piecesCounterId}`).length) {
      const pieceCount = `${
        parseInt($(`#${piecesCounterId}`).html(), 10) - piecesToSubtract
      }`;
      $(`#${piecesCounterId}`).html(pieceCount);
      compareToExpectedPiecesCount(parseInt(pieceCount));
    }
  };

  const pop_row = (t, row, target, splice_index) => {
    row.fadeOut('fast', () => {});
    row.prependTo(target).fadeIn('fast');
    if (splice_index !== -1) {
      return window.markedRowIds.splice(splice_index, 1);
    }
  };

  window.markAndUnmarkRowItems = function (tableID) {
    const unmarkedTableId = `${tableID}-table`;
    const markedTableId = `${tableID}-marked-items`;
    const counterId = `${tableID}-marked-items-count`;
    const piecesCounterId = `${tableID}-marked-pieces-count`;

    $(`#${unmarkedTableId} > tbody`).on('click', 'tr', function () {
      const $this = $(this);
      const $markedItemsTbody = $(`#${markedTableId} > tbody`);
      const piecesCount = parseInt($this.children('.pieces').first().text());

      if (!Array.from(window.markedRowIds).includes(this.id)) {
        push_row(this, $this, $markedItemsTbody);
        increment_counter(counterId);
        addToPiecesCount(piecesCounterId, piecesCount);
      }
    });

    $(`#${markedTableId} > tbody`).on('click', 'tr', function () {
      const $this = $(this);
      const $unmarkedItemsTbody = $(`#${unmarkedTableId} > tbody`);
      const piecesCount = parseInt($this.children('.pieces').first().text());
      const index = window.markedRowIds.indexOf(this.id);

      if (Array.from(window.markedRowIds).includes(this.id)) {
        pop_row(this, $this, $unmarkedItemsTbody, index);
        decrement_counter(counterId);
        subtractFromPiecesCount(piecesCounterId, piecesCount);
      }
    });
  };

  $('a.mark_all_button').click((e) => {
    e.preventDefault();
    e.stopImmediatePropagation();

    const table = $('.dataTable');
    const tableID = table.attr('id').split('-')[0];
    const counterId = `${tableID}-marked-items-count`;
    const piecesCounterId = `${tableID}-marked-pieces-count`;
    const unmarkedTableId = `${tableID}-table`;
    const markedTableId = `${tableID}-marked-items`;
    const $markedItemsTbody = $(`#${markedTableId} > tbody`);

    const trs = $(`#${unmarkedTableId} > tbody`).find('tr');

    trs.each((i, tr) => {
      push_row(tr, $(tr), $markedItemsTbody);
      increment_counter(counterId);
      const piecesCount = parseInt($(tr).children('.pieces').first().text());
      addToPiecesCount(piecesCounterId, piecesCount);
    });
  });

  $('a.unmark_all_button').click((e) => {
    e.preventDefault();
    e.stopImmediatePropagation();

    const table = $('.dataTable');
    const tableID = table.attr('id').split('-')[0];
    const counterId = `${tableID}-marked-items-count`;
    const piecesCounterId = `${tableID}-marked-pieces-count`;
    const unmarkedTableId = `${tableID}-table`;
    const markedTableId = `${tableID}-marked-items`;
    const $unmarkedItemsTbody = $(`#${unmarkedTableId} > tbody`);

    const trs = $(`#${markedTableId} > tbody`).find('tr');

    trs.each((i, tr) => {
      const index = window.markedRowIds.indexOf(tr.id);
      pop_row(tr, $(tr), $unmarkedItemsTbody, index);
      decrement_counter(counterId);
      const piecesCount = parseInt($(tr).children('.pieces').first().text());
      subtractFromPiecesCount(piecesCounterId, piecesCount);
    });
  });

  window.markedRowIds = [];
});
