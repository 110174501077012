import { Controller } from 'stimulus';

export default class InboundShipmentFormController extends Controller {
  static targets = [
    'piecesCheckedIn',
    'piecesCheckedOut',
    'doNotChangePiecesCheckedInMessage',
    'doNotChangePiecesCheckedOutMessage',
  ];

  connect() {
    if (!this.hasPiecesCheckedInTarget || !this.hasPiecesCheckedOutTarget) {
      return;
    }
    this.originalPiecesCheckedIn = this.piecesCheckedInTarget.value;
    this.originalPiecesCheckedOut = this.piecesCheckedOutTarget.value;
  }

  piecesCheckedInChanged() {
    if (!this.hasPiecesCheckedInTarget || !this.hasPiecesCheckedOutTarget) {
      return;
    }
    const isModified =
      this.piecesCheckedInTarget.value !== this.originalPiecesCheckedIn;
    this.piecesCheckedOutTarget.disabled = isModified;
    this.doNotChangePiecesCheckedOutMessageTarget.classList.toggle(
      'hidden',
      !isModified,
    );
  }

  piecesCheckedOutChanged() {
    if (!this.hasPiecesCheckedInTarget || !this.hasPiecesCheckedOutTarget) {
      return;
    }
    const isModified =
      this.piecesCheckedOutTarget.value !== this.originalPiecesCheckedOut;
    this.piecesCheckedInTarget.disabled = isModified;
    this.doNotChangePiecesCheckedInMessageTarget.classList.toggle(
      'hidden',
      !isModified,
    );
  }
}
